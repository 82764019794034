/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-expressions */
/* eslint-disable eqeqeq */
import firebase from 'firebase/app';
import fetch from 'isomorphic-unfetch';
import ApolloLinkTimeout from '@lib/apollo/apolloLinkTimeout';
import Cookies from 'js-cookie';
import { removeIsLoginFlagging } from '@helper_auth';
import { removeCartId } from '@helper_cartid';
import { onError } from 'apollo-link-error';
import { RetryLink } from 'apollo-link-retry';
import { getAppEnv } from '@helpers/env';
import {
    ApolloClient,
    ApolloLink,
    from,
    HttpLink,
    InMemoryCache,
    gql,
} from '@apollo/client';
import {
    graphqlEndpoint,
    storeCode,
    requestTimeout,
    customerTokenKeyClient,
    custDataNameCookie,
    nameGlobalCookie,
} from '@root/swift.config.js';

const appEnv = getAppEnv();
const uri = graphqlEndpoint[appEnv] ? graphqlEndpoint[appEnv] : graphqlEndpoint.dev;

// handle if token expired
const logoutLink = onError((err) => {
    const { graphQLErrors, networkError } = err;
    const isErrorGQL = graphQLErrors && graphQLErrors.length > 0;
    const message = isErrorGQL ? graphQLErrors[0].message : undefined;
    if (networkError && typeof window !== 'undefined' && isErrorGQL && graphQLErrors[0].status > 500) {
        window.location.href = '/maintenance';
    } else if (
        (isErrorGQL && graphQLErrors[0].status === 401 && typeof window !== 'undefined')
        || message?.includes('The request is allowed for logged in customer')
        || message?.includes("The current customer isn't authorized.")
    ) {
        Cookies.remove(custDataNameCookie);
        Cookies.remove(nameGlobalCookie);
        Cookies.remove(customerTokenKeyClient);
        Cookies.remove('admin_id');
        Cookies.remove('login_with_phone');
        Cookies.remove('uid_product_compare');
        removeIsLoginFlagging();
        removeCartId();
        if (typeof firebase?.auth === 'function') {
            firebase?.auth()?.signOut();
        }
        // reference https://stackoverflow.com/questions/10339567/javascript-clear-cache-on-redirect
        window.location.href = `/customer/account/login?n=${new Date().getTime()}`;
    }
});

const timeoutLink = new ApolloLinkTimeout(requestTimeout); // 10 second timeout

const link = new RetryLink().split(
    (operation) => operation.getContext().request === 'internal',
    new HttpLink({
        uri, // Server URL (must be absolute)
        credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
        fetch,
    }),
    new HttpLink({
        uri, // Server URL (must be absolute)
        credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
        fetch,
        useGETForQueries: true,
    }),
);

const typeDefs = gql`
    extend type Token {
        is_login: Boolean
        originalToken: String
        token: String
        message: String
        is_email_confirmation: Boolean
    }
    extend type RevokeCustomerTokenOutput {
        result: Boolean
    }
    extend type Mutation {
        internalDeleteCustomerToken: RevokeCustomerTokenOutput
        internalGenerateCustomerToken(username: String!, password: String!): Token
    }
`;

const resolvers = {
    Mutation: {
        internalDeleteCustomerToken: async () => ({
            result: true,
        }),
    },
};
export default function createApolloClient(initialState, ctx) {
    // The `ctx` (NextPageContext) will only be present on the server.
    // use it to extract auth headers (ctx.req) or similar.
    let store_code_storage = Cookies.get('store_code_storage');
    if (ctx && ctx.req) {
        if (typeof window === 'undefined') {
            store_code_storage = ctx.req.cookies.store_code_storage || store_code_storage;
        }
    }

    /**
     * Meddleware to customize headers
     */
    const middlewareHeader = new ApolloLink((operation, forward) => {
        const { operationName } = operation;
        const blacklistHeaderCheckout = [
            'MpDailyDeals',
            'Product',
            'getDetailproduct',
            'getProductsOppoCare',
            'getInstallment',
            'getProductList',
            'getProductAggregations',
            'getProducts',
            'getCmsPage',
            'getCmsBlock',
            'getStoreConfig',
            'getCheckoutConfigurations',
        ];
        // const whitelistHeaderCheckout = [
        //     'setShippingMethod',
        //     'setPaymentMethod',
        //     'updatedDefaultAddress',
        //     'setShippingAddressById',
        //     'placeOrder'
        // ];
        const additionalHeader = {};

        if (storeCode !== '') {
            additionalHeader.store = storeCode;
        } else if (store_code_storage && store_code_storage !== '' && storeCode === '') {
            additionalHeader.store = store_code_storage;
        }

        const token = Cookies.get(customerTokenKeyClient);
        const store = Cookies.get('store_code_storage');
        const adminId = Cookies.get('admin_id');
        const checkoutToken = Cookies.get('checkout_token');
        if (token && !blacklistHeaderCheckout?.includes(operationName)) additionalHeader.Authorization = `Bearer ${token}`;
        if (store) additionalHeader.Store = store;
        if (adminId) {
            const admin = parseInt(JSON.parse(adminId)[0], 10);
            additionalHeader['Admin-Id'] = admin;
        }
        // && whitelistHeaderCheckout?.includes(operationName)
        if (checkoutToken && checkoutToken != 'undefined' && !blacklistHeaderCheckout?.includes(operationName)) {
            additionalHeader['Checkout-Token'] = checkoutToken;
        }

        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                ...additionalHeader,
            },
        }));

        return forward(operation);
    });

    return new ApolloClient({
        ssrMode: Boolean(ctx),
        link: from([timeoutLink, middlewareHeader, logoutLink, link]),
        cache: new InMemoryCache({
            possibleTypes: {
                ProductInterface: [
                    'ConfigurableProduct', 'SimpleProduct', 'BundleProduct',
                    'VirtualProduct', 'DownloadableProduct', 'GroupedProduct',
                ],
            },
            typePolicies: {
                Query: {
                    fields: {
                        products: {
                            merge(existing = [], incoming) {
                                return { ...existing, ...incoming };
                            },
                        },
                    },
                },

                ProductImage: {
                    keyFields: ['url'],
                },
            },
        }).restore(initialState),
        // reference https://www.apollographql.com/docs/react/development-testing/developer-tooling/#apollo-client-devtools
        // eslint-disable-next-line no-underscore-dangle
        connectToDevTools: typeof window !== 'undefined' && window.__APOLLO_CLIENT__ && appEnv === 'local',
        resolvers,
        typeDefs,
    });
}
